"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowListener = exports.buildRoute = exports.isItemRma = exports.isItemOrder = exports.isItemAsn = exports.isItemSku = void 0;
const react_1 = require("react");
const isItemSku = (i) => i.type === "SKU";
exports.isItemSku = isItemSku;
const isItemAsn = (i) => i.type === "ASN";
exports.isItemAsn = isItemAsn;
const isItemOrder = (i) => i.type === "ORDER";
exports.isItemOrder = isItemOrder;
const isItemRma = (i) => i.type === "RMA";
exports.isItemRma = isItemRma;
const buildRoute = (item) => {
    switch (item.type) {
        case "SKU":
            return `/WOM/skus/${item.id}`;
        case "ASN":
            return `/WOM/inbound_shipments/${item.id}`;
        case "ORDER":
            return `/WOM/orders/${item.id}`;
        case "RMA":
            return `/WOM/rmas/${item.id}`;
        default:
            throw new TypeError(`Invalid search item type: ${item.type}`);
    }
};
exports.buildRoute = buildRoute;
const useWindowListener = (eventName, handler) => {
    (0, react_1.useEffect)(() => {
        window.addEventListener(eventName, handler);
        return () => {
            window.removeEventListener(eventName, handler);
        };
    });
};
exports.useWindowListener = useWindowListener;
