import { Environment, FetchFunction, Network, RecordSource, Store } from "relay-runtime"
import { WOM_ORIGIN } from "@root/constants"
import fetchGraphql from "./FetchGraphql"

const WOM_URL = WOM_ORIGIN + "/api/v2/graphql"

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
export const fetchRelayWom: FetchFunction = async (params, variables) => {
  console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`)
  return fetchGraphql(params.text, WOM_URL, variables)
}

// Export a singleton instance of Relay Environment configured with our network function:
export const womEnvironment = new Environment({
  network: Network.create(fetchRelayWom),
  store: new Store(new RecordSource()),
})
