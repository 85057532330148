"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNavigationDefinitions = void 0;
function getNavigationDefinitions(womOrigin, accountSlug, t) {
    var _a;
    const base = [
        {
            id: "iframe.dashboard",
            title: t("navigation:links.dashboard"),
            link: "/WOM/dashboard",
            icon: "home",
            urlMatches: [/^\/$/i, /^\/(WOM\/dashboard)/i],
            isActive: p => !p.includes("NAV_OPS_DASHBOARD"),
        },
        {
            id: "iframe.dashboard",
            title: t("navigation:links.dashboard_opsDashboard"),
            link: "/WOM/dashboard/ops",
            icon: "chart line",
            urlMatches: [/^\/(WOM\/dashboard\??)?$/i, /^\/(WOM\/dashboard\/ops)/i],
            isActive: p => p.includes("NAV_OPS_DASHBOARD"),
        },
        {
            id: "iframe.catalog",
            title: t("navigation:links.catalog"),
            icon: "barcode",
            urlMatches: [/^\/WOM\/skus\/[0-9a-f-]+/i],
            isActive: true,
            subitems: [
                {
                    id: "iframe.catalog.allSkus",
                    title: t("navigation:links.catalog_allSkus"),
                    link: "/WOM/v2/skus",
                    urlMatches: [
                        /^\/WOM\/v2\/skus$/i,
                        path => path.indexOf("/WOM/v2/skus") === 0 &&
                            path.indexOf("inventory_levels=isBackorder") < 0,
                    ],
                    isActive: true,
                },
                {
                    id: "iframe.catalog.backorderedSkus",
                    title: t("navigation:links.catalog_backorderedSkus"),
                    link: "/WOM/v2/skus?inventory_levels=isBackorder",
                    urlMatches: [/^\/WOM\/v2\/skus\?.*?&?inventory_levels=isBackorder/i],
                    isActive: true,
                },
                {
                    id: "iframe.catalog.createSku",
                    title: t("navigation:links.catalog_createSku"),
                    link: "/WOM/skus/new",
                    urlMatches: [/^\/WOM\/skus\/new/i],
                    isActive: p => p.includes("SKU_CREATE"),
                },
                {
                    id: "iframe.catalog.findByUpc",
                    title: t("navigation:links.catalog_findByUpc"),
                    link: "/WOM/search/skus",
                    urlMatches: [/^\/WOM\/search\/skus/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.catalog.oversellPolicies",
                    title: t("navigation:links.catalog_oversellPolicies"),
                    link: "/WOM/oversell_policies",
                    urlMatches: [/^\/WOM\/oversell_policies/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.catalog.inventorySnapshots",
                    title: t("navigation:links.catalog_inventorySnapshots"),
                    link: "/WOM/snapshot_requests",
                    urlMatches: [/^\/WOM\/snapshot_requests/i],
                    isActive: true,
                },
            ],
        },
        {
            id: "iframe.asns",
            title: t("navigation:links.inboundShipments"),
            icon: "list alternate outline",
            urlMatches: [/^\/WOM\/inbound_shipments\/[0-9a-f-]+/i],
            isActive: true,
            subitems: [
                {
                    id: "iframe.asns.inboundShipments",
                    title: t("navigation:links.inboundShipments_allInboundShipments"),
                    link: "/WOM/v2/inbound_shipments",
                    urlMatches: [/^\/WOM\/v2\/inbound_shipments/i],
                    isActive: true,
                },
                {
                    id: "iframe.asns.uploadAsn",
                    title: t("navigation:links.inboundShipments_uploadAsn"),
                    link: "/WOM/upload/inbound_shipments",
                    urlMatches: [/^\/WOM\/upload\/inbound_shipments/i],
                    isActive: true,
                },
                {
                    id: "iframe.asns.findBySku",
                    title: t("navigation:links.inboundShipments_findBySku"),
                    link: "/WOM/sku_search/inbound_shipments",
                    urlMatches: [/^\/WOM\/sku_search\/inbound_shipments/i],
                    isActive: true,
                },
                {
                    id: "iframe.asns.findByTracking",
                    title: t("navigation:links.inboundShipments_findByTracking"),
                    link: "/WOM/search/inbound_shipments",
                    urlMatches: [/^\/WOM\/search\/inbound_shipments/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
            ],
        },
        {
            id: "iframe.orders",
            title: t("navigation:links.orders"),
            icon: "box",
            urlMatches: [/^\/WOM\/orders\/[0-9a-f-]+/i],
            isActive: true,
            subitems: [
                {
                    id: "iframe.orders.ordersAndShipments",
                    title: t("navigation:links.orders_allOrdersAndShipments"),
                    link: "/OMS/orders",
                    urlMatches: [/^\/OMS\/orders/i],
                    isActive: true,
                },
                {
                    id: "iframe.orders.backorders",
                    title: t("navigation:links.orders_allBackorders"),
                    link: "/WOM/backorders",
                    urlMatches: [/^\/WOM\/backorders/i],
                    isActive: true,
                },
                {
                    id: "iframe.orders.createOrder",
                    title: t("navigation:links.orders_createOrder"),
                    link: "/WOM/orders/new",
                    urlMatches: [/^\/WOM\/orders\/new/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.orders.uploadOrders",
                    title: t("navigation:links.orders_uploadOrders"),
                    link: "/WOM/upload/orders",
                    urlMatches: [/^\/WOM\/upload\/orders/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.orders.findByTracking",
                    title: t("navigation:links.orders_findByTracking"),
                    link: "/WOM/search/orders",
                    urlMatches: [/^\/WOM\/search\/orders/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.orders.printPackSlipByLabel",
                    title: t("navigation:links.orders_printPackSlipByLabel"),
                    link: "/WOM/bulk_scan_print",
                    urlMatches: [/^\/WOM\/bulk_scan_print/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.orders.orderUpdateRequests",
                    title: t("navigation:links.orders_orderUpdateRequests"),
                    link: "/WOM/order_update_requests",
                    urlMatches: [/^\/WOM\/order_update_requests/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
            ],
        },
        {
            id: "iframe.rmas",
            title: t("navigation:links.returns"),
            icon: "exchange",
            urlMatches: [/^\/WOM\/rmas\/[0-9a-f-]+/i],
            isActive: true,
            subitems: [
                {
                    id: "iframe.rmas.rmas",
                    title: t("navigation:links.returns_allReturns"),
                    link: "/WOM/summary_rmas",
                    urlMatches: [/^\/WOM\/summary_rmas/i],
                    isActive: true,
                },
                {
                    id: "iframe.rmas.createRma",
                    title: t("navigation:links.returns_createRma"),
                    link: "/WOM/create/rma",
                    urlMatches: [/^\/WOM\/create\/rma/i],
                    isActive: true,
                },
                {
                    id: "iframe.rmas.uploadRmas",
                    title: t("navigation:links.returns_uploadRmas"),
                    link: "/WOM/upload/rmas",
                    urlMatches: [/^\/WOM\/upload\/rmas/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
                {
                    id: "iframe.rmas.findByPackageId",
                    title: t("navigation:links.returns_findByPackageId"),
                    link: "/WOM/search/rmas",
                    urlMatches: [/^\/WOM\/search\/rmas/i],
                    isActive: p => p.includes("MASONHUB_ADMIN"),
                },
            ],
        },
        {
            id: "iframe.printStations",
            title: t("navigation:links.printStations"),
            icon: "print",
            link: "/WOM/print_stations",
            urlMatches: [/^\/WOM\/print_stations/i],
            isActive: p => p.includes("MASONHUB_ADMIN"),
        },
        {
            id: "iframe.accountManagement",
            title: t("navigation:links.accountManagement"),
            icon: "user",
            urlMatches: [],
            isActive: p => p.includes("CLIENT_ADMIN"),
            subitems: [
                {
                    id: "iframe.accountManagement.accountProfile",
                    title: t("navigation:links.accountManagement_accountProfile"),
                    link: `/OMS/account-management/accounts/${encodeURIComponent(accountSlug)}`,
                    urlMatches: [/^\/OMS\/account-management\/accounts/i],
                    isActive: true,
                },
                {
                    id: "iframe.accountManagement.users",
                    title: t("navigation:links.accountManagement_users"),
                    link: "/WOM/users/",
                    urlMatches: [/^\/WOM\/users/i],
                    isActive: true,
                },
            ],
        },
        {
            id: "iframe.invitations",
            title: t("navigation:links.invitations"),
            urlMatches: [],
            isActive: p => !p.includes("MASONHUB_ADMIN") && p.includes("CLIENT_ADMIN"),
            subitems: [
                {
                    id: "iframe.invitations.list",
                    title: t("navigation:links.invitations_invitationList"),
                    link: "/WOM/invitations",
                    urlMatches: [/^\/WOM\/invitations$/i],
                    isActive: true,
                },
                {
                    id: "iframe.invitations.new",
                    title: t("navigation:links.invitations_newInvitation"),
                    link: "/WOM/invitations/new",
                    urlMatches: [/^\/WOM\/invitations\/new/i],
                    isActive: true,
                },
            ],
        },
        {
            id: "iframe.adminAccountManagement",
            title: t("navigation:links.accountManagement"),
            icon: "user",
            urlMatches: [/^\/OMS\/accounts/i, /^\/OMS\/account-management\/accounts/i],
            isActive: p => p.includes("MASONHUB_ADMIN"),
            subitems: [
                {
                    id: "iframe.adminAccountManagement.accounts",
                    title: t("navigation:links.accountManagement_accounts"),
                    link: "/OMS/account-management/accounts",
                    urlMatches: [/^\/OMS\/account-management\/accounts\/?$/i],
                    isActive: true,
                },
                {
                    id: "iframe.adminAccountManagement.users",
                    title: t("navigation:links.accountManagement_users"),
                    link: "/WOM/users",
                    urlMatches: [/^\/WOM\/users/i],
                    isActive: true,
                },
                {
                    id: "iframe.invitations.list",
                    title: t("navigation:links.invitations_invitationList"),
                    link: "/WOM/invitations",
                    urlMatches: [/^\/WOM\/invitations$/i],
                    isActive: true,
                },
                {
                    id: "iframe.invitations.new",
                    title: t("navigation:links.invitations_newInvitation"),
                    link: "/WOM/invitations/new",
                    urlMatches: [/^\/WOM\/invitations\/new/i],
                    isActive: true,
                },
                {
                    id: "iframe.adminAccountManagement.daysOff",
                    title: t("navigation:links.accountManagement_daysOff"),
                    link: "/WOM/days_off",
                    urlMatches: [/^\/WOM\/days_off/i],
                    isActive: true,
                },
            ],
        },
        {
            id: "iframe.callbacks",
            title: t("navigation:links.callbacks"),
            icon: "cogs",
            link: "/WOM/callbacks",
            urlMatches: [/^\/WOM\/callbacks$/i],
            isActive: p => p.includes("MASONHUB_ADMIN"),
        },
        {
            id: "uiV1",
            title: t("navigation:links.uiV1"),
            icon: "reply",
            link: womOrigin,
            urlMatches: [],
            isActive: true,
        },
        {
            id: "iframe.logout",
            title: t("navigation:links.logout"),
            icon: "sign out alternate",
            link: `${womOrigin}/logout`,
            urlMatches: [],
            isActive: true,
        },
    ];
    for (const mainItem of base) {
        if ((_a = mainItem.subitems) === null || _a === void 0 ? void 0 : _a.length) {
            mainItem.urlMatches = mainItem.subitems.reduce((acc, i) => acc.concat(i.urlMatches), mainItem.urlMatches);
        }
    }
    return base;
}
exports.getNavigationDefinitions = getNavigationDefinitions;
