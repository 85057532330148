import { FC, Fragment, useEffect, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { Icon, Message } from "semantic-ui-react"

import { WOM_ORIGIN } from "@root/constants"
import { Loader } from "./loader"

type StdError = { message: string; requestId?: string; status?: number; stack?: string }
type StdErrorList = Array<StdError>

export const DisplayError: FC<{ error: unknown }> = ({ error }) => {
  const ref = useRef("")
  const [state, setState] = useState(
    null as {
      isInvalidSession: boolean
      errors: StdErrorList
    } | null,
  )

  useEffect(() => {
    const errorHash = JSON.stringify(error)
    if (error == null && ref.current !== "") {
      ref.current = ""
      setState(null)
    } else if (error != null && ref.current !== errorHash) {
      ref.current = errorHash
      const errors: StdErrorList = isNetworkError(error)
        ? error.source.errors.map(err => (isStdError(err) ? err : buildStandardError(err)))
        : [buildStandardError(error)]

      const isInvalidSession = !!errors.find(err => err.status === 401)

      setState({
        isInvalidSession,
        errors,
      })
    }
  }, [error])

  return error != null ? (
    <Message>
      <Message.Header>
        <div className="flex mb-4 text-red-600">
          <Icon name="exclamation circle" />
          <span className="pl-1">
            <Trans i18nKey="common:generalError" />
          </span>
        </div>
      </Message.Header>
      <Message.Content>
        {state ? (
          state.isInvalidSession ? (
            <Fragment>
              <p>
                <Trans i18nKey="common:invalidSessionMessage">
                  <a href={WOM_ORIGIN}>link</a>
                </Trans>{" "}
              </p>
              <RequestId key="-" requestId={state.errors[0]?.requestId} />
            </Fragment>
          ) : (
            state.errors
              .map(({ message }, idx) => <p key={idx}>{message}</p>)
              .concat(<RequestId key="-" requestId={state.errors[0]?.requestId} />)
          )
        ) : (
          <Loader active />
        )}
      </Message.Content>
    </Message>
  ) : null
}

const RequestId: FC<{ requestId?: string }> = ({ requestId }) =>
  requestId ? (
    <p className="text-gray-400">
      <small>
        <Trans i18nKey="common:requestId" />: <span>{requestId}</span>
      </small>
    </p>
  ) : null

const isNetworkError = (
  i: any,
): i is { name: string; messageFormat: string; source: { errors: unknown[] } } =>
  Boolean(i?.name === "RelayNetwork" && i?.source?.errors)

const UNKNOWN_ERROR = "UNKNOWN_ERROR"

const isStdError = (i: any): i is StdError =>
  typeof i?.message === "string" &&
  typeof i?.requestId === "string" &&
  typeof i?.status === "number"

const buildStandardError = (i: any): StdError => {
  let message = typeof i?.message === "string" ? i.message : i ? String(i) : UNKNOWN_ERROR
  const stack = typeof i?.stack === "string" ? i.stack : undefined
  const requestId = i?.requestId || undefined

  if (typeof i?.error === "string") {
    message = `${i.error} -> ${message}`
  }

  return { message, requestId, stack }
}
