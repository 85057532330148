"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageFallback = exports.NoImage = exports.NoProductImage = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("react");
exports.NoProductImage = "/static/media/no-product-image.png";
exports.NoImage = "/static/media/no-image.png";
const ImageFallback = props => {
    const [imgUrl, setImgUrl] = (0, react_1.useState)(() => props.src);
    const handleError = e => {
        var _a;
        setImgUrl(getNoImageUrl(props.fallback));
        (_a = props.onError) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    // @ts-ignore
    return (0, jsx_runtime_1.jsx)("img", Object.assign({}, props, { src: imgUrl, onError: handleError }));
};
exports.ImageFallback = ImageFallback;
const getNoImageUrl = (v) => {
    switch (v) {
        case "no-product":
            return exports.NoProductImage;
        default:
            return exports.NoImage;
    }
};
