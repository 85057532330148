"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeDisplay = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const react_2 = require("react");
const router_1 = require("@reach/router");
const semantic_ui_react_1 = require("semantic-ui-react");
const navigation_1 = require("../navigation");
const IframeDisplay = ({ accountSlug, }) => {
    // currentUrlRef is just a reference to avoid updating the iframe URL
    // when the user clicks on a link within the iframe. We wanna update
    // the window location whenever iframe URL changes.
    const currentUrlRef = (0, react_2.useRef)("");
    const loadingRef = (0, react_2.useRef)(null);
    const navigate = (0, router_1.useNavigate)();
    const location = (0, router_1.useLocation)();
    const { womOrigin, womUrlPrefix } = (0, navigation_1.useWomUrlInfo)();
    const currentUrl = cleanUrl(location.href).toString();
    const [iframeUrl, setIframeUrl] = (0, react_2.useState)(() => getWomUrl(womOrigin, womUrlPrefix, accountSlug, currentUrl));
    const [isIframeLoading, setIframeLoading] = (0, react_2.useState)(false);
    const clearInProgressSpinner = () => {
        if (loadingRef.current) {
            window.clearTimeout(loadingRef.current);
        }
    };
    const displaySpinner = () => {
        clearInProgressSpinner();
        loadingRef.current = window.setTimeout(() => {
            setIframeLoading(true);
        }, 400);
    };
    const removeSpinner = () => {
        clearInProgressSpinner();
        setIframeLoading(false);
    };
    (0, react_2.useEffect)(() => {
        if (currentUrlRef.current !== currentUrl) {
            setIframeUrl(getWomUrl(womOrigin, womUrlPrefix, accountSlug, currentUrl));
        }
        // Reset so next location update will check against either a new value
        // coming from effect below or the value stays empty string
        currentUrlRef.current = "";
    }, [accountSlug, currentUrl]);
    // Component unmount clean up
    (0, react_2.useEffect)(() => {
        displaySpinner();
        return () => {
            clearInProgressSpinner();
        };
    }, []);
    (0, react_2.useEffect)(() => {
        function handleMessage(event) {
            // IMPORTANT: check the origin of the data!
            if (event.isTrusted && event.origin.startsWith(womOrigin)) {
                switch (event.data.type) {
                    case "syncLocation": {
                        const destination = getHudCompatiblePath(womOrigin, womUrlPrefix, accountSlug, event.data.url);
                        if (destination !== getPathname(currentUrl)) {
                            const url = new URL(destination, currentUrl).toString();
                            // Keep a copy so later we can avoid updating the iframe URL
                            currentUrlRef.current = url;
                            navigate(destination, 
                            // Every location change inside the iframe will push a new step in the browser history.
                            // We skip adding this location change to the history stack to avoid having double steps.
                            {
                                replace: true,
                            });
                        }
                        break;
                    }
                    case "standardEvent": {
                        const { eventType, detail } = event.data;
                        document.dispatchEvent(new CustomEvent(`wom_${eventType}`, { detail }));
                        if (eventType === "unload") {
                            displaySpinner();
                        }
                        break;
                    }
                    default:
                        console.error("Invalid iFrame message type:", event.data);
                }
            }
        }
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    });
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ css: containerCss }, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Loader, { active: isIframeLoading, size: "large", indeterminate: true }), (0, jsx_runtime_1.jsx)("iframe", { src: iframeUrl, width: "100%", height: "100%", title: "Web Order Management", onLoad: removeSpinner, sandbox: "allow-forms allow-modals allow-popups allow-scripts allow-same-origin" }, iframeUrl)] })));
};
exports.IframeDisplay = IframeDisplay;
function cleanUrl(url) {
    const u = new URL(url);
    u.searchParams.delete("hudRef");
    return u;
}
function getWomUrl(womOrigin, womUrlPrefix, accountSlug, u) {
    const url = new URL(u);
    url.host = new URL(womOrigin).host;
    url.pathname = url.pathname.replace(womUrlPrefix, accountSlug);
    url.searchParams.set("hudRef", Date.now().toString());
    return url.toString();
}
function getHudCompatiblePath(womOrigin, womUrlPrefix, accountSlug, url) {
    const u = new URL(url);
    if (u.pathname.indexOf(accountSlug) >= 0) {
        u.pathname = u.pathname.replace(`${accountSlug}/`, "");
        u.searchParams.delete("hudRef");
    }
    return u.toString().replace(womOrigin, `/${womUrlPrefix}`);
}
function getPathname(i) {
    const url = new URL(i);
    const host = url.host || "";
    const protocol = url.protocol || "";
    url.searchParams.delete("hudRef");
    return url.toString().replace(`${protocol}//${host}`, "");
}
const containerCss = (0, react_1.css) `
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .ui.loader {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms, visibility 0ms 200ms;
    user-select: none;
    pointer-events: none;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 200ms, visibility 0ms;
    }
  }
`;
