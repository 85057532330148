"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWomUrlInfo = exports.useNavigationSelection = exports.useNavigationContext = exports.NavigationContextProvider = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("react");
const router_1 = require("@reach/router");
const react_i18next_1 = require("react-i18next");
const account_features_1 = require("../../account-features");
const nav_definitions_1 = require("./nav-definitions");
const util_1 = require("./util");
const NavigationContext = (0, react_1.createContext)([
    {
        selected: "",
    },
    () => { },
]);
const WomUrlInfoContext = (0, react_1.createContext)({
    womOrigin: "",
    womUrlPrefix: "",
    appPublicUrl: "",
    userType: "INVALID",
    accountFeatures: {},
});
const NavigationContextProvider = ({ womOrigin, womUrlPrefix, appPublicUrl, userType, accountSlug, accountFeatures, children, }) => {
    const stateValue = (0, react_1.useState)({ selected: "" });
    const { t } = (0, react_i18next_1.useTranslation)();
    const location = (0, router_1.useLocation)();
    const locationPath = location.pathname + location.search;
    const permissions = (0, react_1.useMemo)(() => [
        userType,
        accountFeatures[account_features_1.ACCOUNT_FEATURES.skuCreateEdit] ? "SKU_CREATE" : undefined,
        accountFeatures[account_features_1.ACCOUNT_FEATURES.opsDashboardNav] ? "NAV_OPS_DASHBOARD" : undefined,
    ].filter(util_1.isString), [accountFeatures, userType]);
    const navItems = (0, react_1.useMemo)(() => (0, nav_definitions_1.getNavigationDefinitions)(womOrigin, accountSlug, t).filter(i => (0, util_1.shouldDisplay)(permissions, i.isActive)), [womOrigin, accountSlug, permissions]);
    const womContextValue = (0, react_1.useMemo)(() => ({
        womOrigin,
        womUrlPrefix,
        appPublicUrl,
        accountSlug,
        accountFeatures,
        userType,
    }), [womOrigin, womUrlPrefix, appPublicUrl, userType]);
    const setState = stateValue[1];
    (0, react_1.useEffect)(() => {
        setState(s => {
            const standardLocationPath = locationPath.replace(`/${womUrlPrefix}`, `/WOM`);
            const newSelectedId = (0, util_1.getSelectedNavId)(navItems, standardLocationPath);
            if ((0, util_1.isSessionValid)(locationPath, Date.now())) {
                return { selected: newSelectedId };
            }
            else if (s.selected !== newSelectedId) {
                return { selected: "" };
            }
            return s;
        });
    }, [locationPath, setState, navItems]);
    return ((0, jsx_runtime_1.jsx)(NavigationContext.Provider, Object.assign({ value: stateValue }, { children: (0, jsx_runtime_1.jsx)(WomUrlInfoContext.Provider, Object.assign({ value: womContextValue }, { children: children })) })));
};
exports.NavigationContextProvider = NavigationContextProvider;
const useNavigationContext = () => {
    return (0, react_1.useContext)(NavigationContext);
};
exports.useNavigationContext = useNavigationContext;
const useNavigationSelection = () => {
    const [state, setState] = (0, react_1.useContext)(NavigationContext);
    const handleChange = (0, react_1.useCallback)((v) => {
        setState(s => (Object.assign(Object.assign({}, s), { selected: v })));
    }, []);
    return [state.selected, handleChange];
};
exports.useNavigationSelection = useNavigationSelection;
const useWomUrlInfo = () => {
    return (0, react_1.useContext)(WomUrlInfoContext);
};
exports.useWomUrlInfo = useWomUrlInfo;
