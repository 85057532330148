import { Variables, RequestParameters, GraphQLResponse } from "relay-runtime"

async function fetchGraphql(
  query: RequestParameters["text"],
  url: string,
  variables?: Variables,
  token?: string,
): Promise<GraphQLResponse> {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  }
  if (token) {
    headers["Authorization"] = `Bearer ${token}`
  }

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: token ? "same-origin" : "include",
  })

  const responseData = await response.json()

  if (!response.ok) {
    return {
      errors: [{ message: "Failed to fetch data" }],
    }
  }

  return responseData
}

export default fetchGraphql
