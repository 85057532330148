"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigation = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const router_1 = require("@reach/router");
const react_i18next_1 = require("react-i18next");
const mh_logo_1 = require("./mh-logo");
const links_1 = require("./links");
const universal_search_1 = require("../universal-search");
const Navigation = () => {
    const { t } = (0, react_i18next_1.useTranslation)();
    return ((0, jsx_runtime_1.jsxs)("div", Object.assign({ css: styles, className: "hud-navigation-container" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "hud-navigation-home" }, { children: (0, jsx_runtime_1.jsx)(router_1.Link, Object.assign({ to: "/", className: "hud-navigation-home-link", title: t("navigation:homePage") }, { children: (0, jsx_runtime_1.jsx)(mh_logo_1.MasonhubLogo, {}) })) })), (0, jsx_runtime_1.jsx)(links_1.NavigationLinks, {}), (0, jsx_runtime_1.jsx)("a", Object.assign({ className: "hud-navigation-help", href: "https://masonhub.zendesk.com/", title: t("navigation:helpCenterTitle") }, { children: (0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "navigation:helpCenter" }) }))] })));
};
exports.Navigation = Navigation;
const styles = (0, react_1.css) `
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  align-items: center;
  height: var(--height);
  max-height: var(--height);
  font-size: 14px;

  a {
    color: #cedce4;
  }

  & > *:not(.hud-navigation-links) {
    flex: 0 0 auto;
  }
  & > .hud-navigation-links {
    flex: 1 1 0px;
    overflow: scroll;
    overflow-x: hidden;

    /* Let's get this party started */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.4);
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background-color: transparent;
    }
  }

  .hud-navigation-home {
    width: 100%;
    padding: 1.3em;
    height: ${universal_search_1.UNIVERSAL_SEARCH_MAX_HEIGHT}px;
    max-height: ${universal_search_1.UNIVERSAL_SEARCH_MAX_HEIGHT}px;
    flex: 0 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  }
  .hud-navigation-help {
    padding: 0.2em;
    margin-bottom: 2em;
  }
  .hud-navigation-home-link {
    width: 72px;
    margin: 0 auto;
    display: block;

    svg path {
      fill: #fff;
      stroke-width: 0;
    }
  }
  .hud-navigation-links {
    width: 100%;
  }
`;
