import { v4 as uuidV4 } from "uuid"
import { Variables, RequestParameters } from "relay-runtime"

import { WOM_ORIGIN } from "@root/constants"

const delay = (ms = 1000): Promise<void> => new Promise(set => setTimeout(set, ms))

async function fetchGraphQL(query: RequestParameters["text"], variables?: Variables) {
  const requestId = uuidV4()
  const response = await fetch("/api/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Request-ID": requestId,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    credentials: "include",
  })

  if (response.status === 401) {
    window.alert(
      "Oops! Something is wrong, we cannot complete the request and have to redirect you to the main page.",
    )

    window.location.href = WOM_ORIGIN

    await delay(3000)
  }

  return response.json()
}

export default fetchGraphQL
