"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowResize = void 0;
const react_1 = require("react");
const useWindowResize = (callback, debounceMs = 500, effectDeps = []) => {
    const timeoutRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        function handleEvent() {
            window.requestAnimationFrame(() => {
                if (timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;
                }
                timeoutRef.current = window.setTimeout(() => {
                    callback({ width: window.innerWidth, height: window.innerHeight });
                }, debounceMs);
            });
        }
        window.addEventListener("resize", handleEvent);
        window.addEventListener("orientationchange", handleEvent);
        window.dispatchEvent(new Event("resize"));
        return () => {
            window.removeEventListener("resize", handleEvent);
            window.removeEventListener("orientationchange", handleEvent);
        };
    }, effectDeps);
};
exports.useWindowResize = useWindowResize;
