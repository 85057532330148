/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UniversalSearchItemType = "ASN" | "ORDER" | "RMA" | "SKU" | "%future added value";
export type searchQueryVariables = {
    query: string;
};
export type searchQueryResponse = {
    readonly universalSearchV1: {
        readonly skus: ReadonlyArray<{
            readonly type: UniversalSearchItemType;
            readonly id: string;
            readonly header: string;
            readonly values: unknown;
            readonly accountId: string;
            readonly accountLogo: string;
            readonly accountName: string;
            readonly imageUrl: unknown | null;
        }>;
        readonly orders: ReadonlyArray<{
            readonly type: UniversalSearchItemType;
            readonly id: string;
            readonly header: string;
            readonly values: unknown;
            readonly accountId: string;
            readonly accountLogo: string;
            readonly accountName: string;
            readonly imageUrl: unknown | null;
        }>;
        readonly asns: ReadonlyArray<{
            readonly type: UniversalSearchItemType;
            readonly id: string;
            readonly header: string;
            readonly values: unknown;
            readonly accountId: string;
            readonly accountLogo: string;
            readonly accountName: string;
            readonly imageUrl: unknown | null;
        }>;
        readonly rmas: ReadonlyArray<{
            readonly type: UniversalSearchItemType;
            readonly id: string;
            readonly header: string;
            readonly values: unknown;
            readonly accountId: string;
            readonly accountLogo: string;
            readonly accountName: string;
            readonly imageUrl: unknown | null;
        }>;
    } | null;
};
export type searchQuery = {
    readonly response: searchQueryResponse;
    readonly variables: searchQueryVariables;
};



/*
query searchQuery(
  $query: String!
) {
  universalSearchV1(query: $query) {
    skus {
      type
      id
      header
      values
      accountId
      accountLogo
      accountName
      imageUrl
    }
    orders {
      type
      id
      header
      values
      accountId
      accountLogo
      accountName
      imageUrl
    }
    asns {
      type
      id
      header
      values
      accountId
      accountLogo
      accountName
      imageUrl
    }
    rmas {
      type
      id
      header
      values
      accountId
      accountLogo
      accountName
      imageUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "header",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountLogo",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageUrl",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "UniversalSearchV1",
    "kind": "LinkedField",
    "name": "universalSearchV1",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UniversalSearchItemV1",
        "kind": "LinkedField",
        "name": "skus",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UniversalSearchItemV1",
        "kind": "LinkedField",
        "name": "orders",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UniversalSearchItemV1",
        "kind": "LinkedField",
        "name": "asns",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UniversalSearchItemV1",
        "kind": "LinkedField",
        "name": "rmas",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "searchQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f88502a53ad9902158ba4006b3ef42cc",
    "id": null,
    "metadata": {},
    "name": "searchQuery",
    "operationKind": "query",
    "text": "query searchQuery(\n  $query: String!\n) {\n  universalSearchV1(query: $query) {\n    skus {\n      type\n      id\n      header\n      values\n      accountId\n      accountLogo\n      accountName\n      imageUrl\n    }\n    orders {\n      type\n      id\n      header\n      values\n      accountId\n      accountLogo\n      accountName\n      imageUrl\n    }\n    asns {\n      type\n      id\n      header\n      values\n      accountId\n      accountLogo\n      accountName\n      imageUrl\n    }\n    rmas {\n      type\n      id\n      header\n      values\n      accountId\n      accountLogo\n      accountName\n      imageUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8a14f7c675a4499ccbe3611fee591df7';
export default node;
