import { FC } from "react"
import { css } from "@emotion/react"
import { Loader as SuiLoader, SemanticSIZES } from "semantic-ui-react"

const styles = css`
  display: inline-block !important;
`

export const Loader: FC<{ active?: boolean; size?: SemanticSIZES; dataTestId?: string }> = ({
  active = true,
  size = "medium",
}) => (active ? <SuiLoader css={styles} inline size={size} /> : null)
