"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigationLinkBuilder = exports.getNavigate = exports.getNavigationDefinitions = void 0;
__exportStar(require("./context"), exports);
__exportStar(require("./nav"), exports);
var nav_definitions_1 = require("./nav-definitions");
Object.defineProperty(exports, "getNavigationDefinitions", { enumerable: true, get: function () { return nav_definitions_1.getNavigationDefinitions; } });
var util_1 = require("./util");
Object.defineProperty(exports, "getNavigate", { enumerable: true, get: function () { return util_1.getNavigate; } });
Object.defineProperty(exports, "useNavigationLinkBuilder", { enumerable: true, get: function () { return util_1.useNavigationLinkBuilder; } });
