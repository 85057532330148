const {
  PUBLIC_URL: REACT_APP_PUBLIC_URL,
  REACT_APP_BUILD_REVISION,
  REACT_APP_ENV,
  REACT_APP_WOM_ORIGIN,
} = process.env

export const PUBLIC_URL = REACT_APP_PUBLIC_URL || "/"

export const WOM_ORIGIN = REACT_APP_WOM_ORIGIN ? REACT_APP_WOM_ORIGIN : ""

export const WOM_URL_PREFIX = "web-v1"

export const APP_ENV = REACT_APP_ENV || "development"

export const BUILD_REVISION = REACT_APP_BUILD_REVISION || "development-revision"
