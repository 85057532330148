import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { tryCatch } from "@masonweb/utils"
import { en } from "@masonweb/translations"

const potentialBrowserLanguage = tryCatch(() => navigator.language.split("-")[0], "en")

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: { en },
    lng: potentialBrowserLanguage,
    fallbackLng: "en",
    defaultNS: "common",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
