import graphql from "babel-plugin-relay/macro"
import { FC, Suspense, useEffect, useState } from "react"
import { usePreloadedQuery, useQueryLoader, PreloadedQuery } from "react-relay"
import { UniversalSearch, UniversalSearchResults } from "@masonweb/hud-components"

import { useUserContext } from "../user"
import { searchQuery } from "./__generated__/searchQuery.graphql"

const DisplaySearchResults: FC<{ queryRef: unknown }> = ({ queryRef }) => {
  const { userType } = useUserContext()
  const { universalSearchV1 } = usePreloadedQuery<searchQuery>(
    SEARCH_QUERY,
    queryRef as PreloadedQuery<searchQuery, Record<string, unknown>>,
  )

  return (
    universalSearchV1 && <UniversalSearchResults data={universalSearchV1} userType={userType} />
  )
}

export const UniversalSearchQuery: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [queryRef, loadQuery] = useQueryLoader<searchQuery>(SEARCH_QUERY)

  return (
    <UniversalSearch
      isLoading={isLoading}
      onQuery={(query: string) => {
        loadQuery({ query })
      }}
    >
      <Suspense fallback={<FallbackLoading onChange={setIsLoading} />}>
        {queryRef && <DisplaySearchResults queryRef={queryRef} />}
      </Suspense>
    </UniversalSearch>
  )
}

const FallbackLoading: FC<{ onChange: (i: boolean) => void }> = ({ onChange }) => {
  useEffect(() => {
    onChange(true)
    return () => {
      onChange(false)
    }
  }, [onChange])

  return null
}

const SEARCH_QUERY = graphql`
  query searchQuery($query: String!) {
    universalSearchV1(query: $query) {
      skus {
        type
        id
        header
        values
        accountId
        accountLogo
        accountName
        imageUrl
      }
      orders {
        type
        id
        header
        values
        accountId
        accountLogo
        accountName
        imageUrl
      }
      asns {
        type
        id
        header
        values
        accountId
        accountLogo
        accountName
        imageUrl
      }
      rmas {
        type
        id
        header
        values
        accountId
        accountLogo
        accountName
        imageUrl
      }
    }
  }
`
