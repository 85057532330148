import { FC } from "react"
import { Link, Redirect, RouteComponentProps, Router } from "@reach/router"
import { IframeDisplay, Layout, NavigationContextProvider } from "@masonweb/hud-components"

import { PUBLIC_URL, WOM_ORIGIN, WOM_URL_PREFIX } from "@root/constants"
import { UniversalSearchQuery } from "./universal-search"
import { useUserContext } from "./user"

const Home: FC<RouteComponentProps> = () => {
  return (
    <div className="p-8">
      <h1>Welcome to Masonhub UI v2</h1>
      <p>
        Something is wrong or you have manually entered the current URL which has no info to display
        at the moment!
      </p>
      <p>
        Click <Link to="/">here</Link> to go to dashboard or you may click on the dashboard from the
        left navigation.
      </p>
    </div>
  )
}

export const MasonwebHud: FC = () => {
  const { userType, account } = useUserContext()

  return (
    <NavigationContextProvider
      womOrigin={WOM_ORIGIN}
      womUrlPrefix={WOM_URL_PREFIX}
      appPublicUrl={PUBLIC_URL}
      accountSlug={account.slug}
      accountFeatures={account.features as Record<string, boolean>}
      userType={userType}
    >
      <Layout search={<UniversalSearchQuery />}>
        <Router primary className="router-wrapper">
          <Redirect from="/" to={`/${WOM_URL_PREFIX}/dashboard`} noThrow />
          <IframeDisplay path={`${WOM_URL_PREFIX}/*`} accountSlug={account.slug} />
          <Home default />
        </Router>
      </Layout>
    </NavigationContextProvider>
  )
}
