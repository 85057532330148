"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalSearchResultsItem = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const react_2 = require("react");
const react_i18next_1 = require("react-i18next");
const datetime_1 = require("../datetime");
const image_1 = require("../image");
const navigation_1 = require("../navigation");
const utils_1 = require("./utils");
const UniversalSearchResultsItem = props => {
    const linkBuilder = (0, navigation_1.useNavigationLinkBuilder)();
    const _memo = (0, react_2.useRef)({});
    const { t } = (0, react_i18next_1.useTranslation)();
    const link = (0, utils_1.buildRoute)(props);
    const anchorProps = linkBuilder(link);
    if ((0, utils_1.isItemSku)(props)) {
        if (_memo.current.ref !== JSON.stringify(props.values.properties)) {
            const value = ((0, jsx_runtime_1.jsx)("div", { children: Object.entries(props.values.properties).map(([key, val]) => ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("span", { children: key }), ": ", val] }, key))) }));
            const ref = JSON.stringify(props.values.properties);
            _memo.current = { value, ref };
        }
        return ((0, jsx_runtime_1.jsx)(Row, Object.assign({}, anchorProps, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "space-y-3 md:space-y-0 md:flex md:space-x-3" }, { children: [(0, jsx_runtime_1.jsx)(AccountLogoColumn, { visible: props.isAccountLogoVisible, accountLogo: props.accountLogo, accountName: props.accountName }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(image_1.ImageFallback, { className: "round", src: props.imageUrl || "/static/media/no-product-image.png", alt: t("common:productImage"), style: { maxWidth: 50 }, fallback: "no-product" }) }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "font-semibold" }, { children: props.header })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("b", { children: props.values.brandName }), " | ", props.values.productName] }), (0, jsx_runtime_1.jsx)("div", { children: props.values.catSubCat })] })), (0, jsx_runtime_1.jsx)("div", { children: _memo.current.value })] })) })));
    }
    else if ((0, utils_1.isItemAsn)(props)) {
        return ((0, jsx_runtime_1.jsx)(Row, Object.assign({}, anchorProps, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "space-y-3 md:space-y-0 md:flex md:space-x-3" }, { children: [(0, jsx_runtime_1.jsx)(AccountLogoColumn, { visible: props.isAccountLogoVisible, accountLogo: props.accountLogo, accountName: props.accountName }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "font-bold" }, { children: props.header })), (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "font-semibold" }, { children: props.values.shipperName })), (0, jsx_runtime_1.jsx)("div", { children: props.values.unitLineString })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:status" }), ":", " ", (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "font-semibold" }, { children: props.values.status }))] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:poNumber" }), ": ", props.values.poNumber] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:expected" }), ":", " ", props.values.expectedArrivalDate ? ((0, jsx_runtime_1.jsx)(datetime_1.DateTime, { datetime: props.values.expectedArrivalDate })) : ((0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:notAvailable" }))] })] }))] })) })));
    }
    else if ((0, utils_1.isItemOrder)(props)) {
        return ((0, jsx_runtime_1.jsx)(Row, Object.assign({}, anchorProps, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "space-y-3 md:space-y-0 md:flex md:space-x-3" }, { children: [(0, jsx_runtime_1.jsx)(AccountLogoColumn, { visible: props.isAccountLogoVisible, accountLogo: props.accountLogo, accountName: props.accountName }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "font-bold" }, { children: props.header })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("span", { children: props.values.shipToName }), " | ", props.values.shipToCity, ",", " ", props.values.shipToLocale] }), (0, jsx_runtime_1.jsx)("div", { children: props.values.unitLineString })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:status" }), ":", " ", (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "font-semibold" }, { children: props.values.status }))] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:shipping" }), ": ", props.values.shipping] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:submittedOn" }), ":", " ", props.values.submittedAt ? ((0, jsx_runtime_1.jsx)(datetime_1.DateTime, { datetime: props.values.submittedAt })) : ((0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:notAvailable" }))] })] }))] })) })));
    }
    else if ((0, utils_1.isItemRma)(props)) {
        return ((0, jsx_runtime_1.jsx)(Row, Object.assign({}, anchorProps, { children: (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "space-y-3 md:space-y-0 md:flex md:space-x-3" }, { children: [(0, jsx_runtime_1.jsx)(AccountLogoColumn, { visible: props.isAccountLogoVisible, accountLogo: props.accountLogo, accountName: props.accountName }), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsx)("div", Object.assign({ className: "font-bold" }, { children: props.header })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("span", Object.assign({ className: "font-semibold" }, { children: props.values.fromName })), " |", " ", props.values.fromCity, ", ", props.values.fromLocale] }), (0, jsx_runtime_1.jsx)("div", { children: props.values.unitLineString })] })), (0, jsx_runtime_1.jsxs)("div", Object.assign({ className: "md:w-1/3" }, { children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:status" }), ":", " ", (0, jsx_runtime_1.jsx)("span", Object.assign({ className: "font-semibold" }, { children: props.values.status }))] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_i18next_1.Trans, { i18nKey: "common:packageId" }), ": ", props.values.packageID] })] }))] })) })));
    }
    return null;
};
exports.UniversalSearchResultsItem = UniversalSearchResultsItem;
const Row = ({ href, children, onClick, }) => {
    return ((0, jsx_runtime_1.jsx)("li", Object.assign({ css: rowStyles, className: "border-light-blue-500" }, { children: (0, jsx_runtime_1.jsx)("a", Object.assign({ className: "block universal-search-result-item", href: href, onClick: onClick }, { children: children })) })));
};
const AccountLogo = ({ logo, name }) => {
    return (0, jsx_runtime_1.jsx)(image_1.ImageFallback, { className: "rounded", src: logo, alt: name, style: { maxWidth: 70 } });
};
const AccountLogoColumn = ({ visible, accountLogo, accountName, }) => {
    return visible ? ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(AccountLogo, { logo: accountLogo, name: accountName }) })) : null;
};
const rowStyles = (0, react_1.css) `
  & > a {
    color: #000;
  }
  &:not(:last-of-type) {
    margin-bottom: 0.6em;
    padding-bottom: 0.6em;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  & > div > div:nth-of-type(1) {
    background-color: red;
  }
`;
