import dayjs from "dayjs"
import dayjsUtc from "dayjs/plugin/utc"
import dayjsTimezone from "dayjs/plugin/timezone"
import dayjsAdvancedFormat from "dayjs/plugin/advancedFormat"
import { FC, createContext, useContext, useEffect } from "react"
import { loadQuery, usePreloadedQuery } from "react-relay"

import { ROOT_USER_QUERY } from "@root/api"
import { womEnvironment } from "@root/api/WomEnv"
import { userQuery, userQueryResponse } from "@root/api/__generated__/userQuery.graphql"

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsAdvancedFormat)

const UserContext = createContext({} as userQueryResponse["currentUser"])

const queryUserRef = loadQuery<userQuery>(womEnvironment, ROOT_USER_QUERY, {})

export const UserContextProvider: FC = ({ children }) => {
  const { currentUser } = usePreloadedQuery(ROOT_USER_QUERY, queryUserRef)

  useEffect(() => {
    dayjs.tz.setDefault(currentUser.timezone)
  }, [currentUser.timezone])

  return <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  return useContext(UserContext)
}
