import React from "react"
import ReactDOM from "react-dom"
import { I18nextProvider } from "react-i18next"
import { RelayEnvironmentProvider } from "react-relay/hooks"
import { LocationProvider } from "@reach/router"
import { Loader } from "semantic-ui-react"
import { DisplayError } from "./display-error"
import { ErrorBoundary } from "./error-boundary"

import "./index.css"
import i18n from "./i18n"
import reportWebVitals from "./reportWebVitals"
import { womEnvironment } from "./api/WomEnv"
import { UserContextProvider, MasonwebHud } from "./components"
import { APP_ENV, PUBLIC_URL, WOM_ORIGIN, BUILD_REVISION } from "./constants"

console.log("HUD App:", { BUILD_REVISION, PUBLIC_URL, APP_ENV, WOM_ORIGIN })

async function bootstrap() {
  await import("semantic-ui-less/semantic.less")

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary
        fallback={(err: unknown) => (
          <div className="p-10">
            <DisplayError error={err} />
          </div>
        )}
      >
        <LocationProvider>
          {/* @ts-ignore */}
          <I18nextProvider i18n={i18n}>
            <RelayEnvironmentProvider environment={womEnvironment}>
              <React.Suspense
                fallback={
                  <div style={{ padding: "1rem" }}>
                    <Loader active />
                  </div>
                }
              >
                <UserContextProvider>
                  <MasonwebHud />
                </UserContextProvider>
              </React.Suspense>
            </RelayEnvironmentProvider>
          </I18nextProvider>
        </LocationProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root"),
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

bootstrap()
