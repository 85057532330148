import { Component, ReactElement, ReactNode } from "react"
import { DisplayError } from "./display-error"

type Props = { fallback?: ReactElement | ((error: Error) => ReactElement); children: ReactNode }
type State = { error: Error | null }

export class ErrorBoundaryClass extends Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  public render() {
    const { error } = this.state
    const { children, fallback } = this.props

    if (error) {
      return fallback == null ? (
        <DisplayError error={error} />
      ) : typeof fallback === "function" ? (
        fallback(error)
      ) : (
        fallback
      )
    }

    return children
  }
}

export const ErrorBoundary = ErrorBoundaryClass as React.ElementType
