import graphql from "babel-plugin-relay/macro"

export const ROOT_USER_QUERY = graphql`
  query userQuery {
    currentUser {
      id
      name
      email
      userType
      timezone
      account {
        slug
        features
      }
    }
  }
`
