"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLayoutContext = exports.LayoutContextProvider = void 0;
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("react");
const breakpoints_1 = require("../breakpoints");
const util_1 = require("./util");
const LayoutContextInitialState = {
    height: window.innerHeight,
    navigationState: "mobileViewClosed",
};
const LayoutContext = (0, react_1.createContext)([LayoutContextInitialState, () => { }]);
const LayoutContextProvider = ({ children }) => {
    const stateValue = (0, react_1.useState)(() => {
        return Object.assign(Object.assign({}, LayoutContextInitialState), { navigationState: getNavigationState("mobileViewClosed", window.innerWidth) });
    });
    const setState = stateValue[1];
    (0, util_1.useWindowResize)(({ height }) => {
        setState(s => {
            let shouldUpdate = false;
            const updatedState = Object.assign({}, s);
            const newNavState = getNavigationState(s.navigationState, window.innerWidth);
            if (s.navigationState !== newNavState) {
                shouldUpdate = true;
                updatedState.navigationState = newNavState;
            }
            if (s.height !== height) {
                shouldUpdate = true;
                updatedState.height = height;
            }
            return shouldUpdate ? updatedState : s;
        });
    }, 100, [setState]);
    return (0, jsx_runtime_1.jsx)(LayoutContext.Provider, Object.assign({ value: stateValue }, { children: children }));
};
exports.LayoutContextProvider = LayoutContextProvider;
const useLayoutContext = () => {
    return (0, react_1.useContext)(LayoutContext);
};
exports.useLayoutContext = useLayoutContext;
const getNavigationState = (currentValue, windowWidth) => {
    if (windowWidth > breakpoints_1.MOBILE_MAX_LANDSCAPE_WIDTH && currentValue !== "desktop") {
        return "desktop";
    }
    else if (windowWidth <= breakpoints_1.MOBILE_MAX_LANDSCAPE_WIDTH && currentValue === "desktop") {
        return "mobileViewClosed";
    }
    return currentValue;
};
