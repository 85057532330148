"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSessionValid = exports.useNavigationLinkBuilder = exports.getNavigate = exports.isString = exports.getDestinationAppUrl = exports.shouldDisplay = exports.getSelectedNavId = void 0;
const react_1 = require("react");
const router_1 = require("@reach/router");
const utils_1 = require("@masonweb/utils");
const context_1 = require("./context");
const APP_SESSION_KEY = "appSession";
let shouldWarnMultiselectedNavigationMatches = true;
const getSelectedNavId = (navigationItems, locationPath) => {
    const selectedRoutes = navigationItems.filter(i => i.urlMatches.length > 0 &&
        i.urlMatches.some(reOrPredicate => typeof reOrPredicate === "function"
            ? reOrPredicate(locationPath)
            : locationPath.match(reOrPredicate)));
    if (selectedRoutes.length > 0) {
        if (selectedRoutes.length === 1) {
            return selectedRoutes[0].id;
        }
        if (shouldWarnMultiselectedNavigationMatches && process.env.NODE_ENV === "development") {
            shouldWarnMultiselectedNavigationMatches = false;
            console.error(`Only one item across all navigation regex definition should always be selected based on current window location. Review your RegExp pattern in 'reMatch' in NavigationItem and make sure only one can be match at all times`);
        }
    }
    return "";
};
exports.getSelectedNavId = getSelectedNavId;
function shouldDisplay(permissions, isActive) {
    if (typeof isActive === "boolean") {
        return isActive;
    }
    return isActive(permissions);
}
exports.shouldDisplay = shouldDisplay;
const APPS = ["WOM", "OMS"];
const getDestinationAppUrl = (publicPath, link, womUrlPrefix) => {
    const url = link
        .replace("/WOM", `/${womUrlPrefix}`)
        .replace("/OMS", "/oms");
    switch (publicPath) {
        case "/oms":
            return [!link.startsWith("/OMS"), url];
        case "/":
            return [link.startsWith("/OMS"), url];
        default:
            throw new TypeError(`Undefined public path: ${publicPath}`);
    }
};
exports.getDestinationAppUrl = getDestinationAppUrl;
const isString = (i) => typeof i === "string";
exports.isString = isString;
const getNavigate = (iframeNavigate, PUBLIC_URL) => (to) => {
    if ((0, utils_1.isWindowInIframe)()) {
        iframeNavigate(to);
    }
    else {
        (0, router_1.navigate)(PUBLIC_URL + (to[0] === "/" ? to : "/" + to));
    }
};
exports.getNavigate = getNavigate;
const useNavigationLinkBuilder = () => {
    const { appPublicUrl, womUrlPrefix } = (0, context_1.useWomUrlInfo)();
    const builder = (0, react_1.useCallback)((link, handler) => {
        if (process.env.NODE_ENV !== "production") {
            const linkParts = link.split("/").filter(Boolean);
            if (linkParts[0] !== "http:" &&
                linkParts[0] !== "https:" &&
                !APPS.includes(linkParts.shift())) {
                throw new TypeError(`Invalid link: The link must be an absolute path starting with one of the registered apps: ${APPS.join()}
Provided: ${link}`);
            }
        }
        const [isHardReload, url] = (0, exports.getDestinationAppUrl)(appPublicUrl, link, womUrlPrefix);
        return {
            href: url,
            onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                if (isHardReload) {
                    setSessionStorageValue(APP_SESSION_KEY, {
                        url,
                        timeout: /* expires in 10 seconds */ Date.now() + 10000,
                    });
                }
                // If there is a handler we call it and if it returns `false` we cancel navigation
                if (typeof handler !== "function" || handler(e) !== false) {
                    if (isHardReload) {
                        window.location.href = url;
                    }
                    else {
                        (0, router_1.navigate)(url);
                    }
                }
            },
        };
    }, [womUrlPrefix]);
    return builder;
};
exports.useNavigationLinkBuilder = useNavigationLinkBuilder;
const setSessionStorageValue = (key, data) => {
    let buf = "";
    try {
        buf = JSON.stringify({ version: 1, data });
    }
    catch (_a) { }
    window.sessionStorage.setItem(key, buf);
    return data;
};
const getSessionStorageValue = (key, fallback) => {
    try {
        const b = window.sessionStorage.getItem(key);
        const buf = JSON.parse(b || "-");
        return buf.data;
    }
    catch (_a) { }
    return fallback;
};
const INITIAL_APP_SESSION_VALUE = { url: "", timeout: 0 };
const isSessionValid = (locationPath, now) => {
    const { url, timeout } = getSessionStorageValue(APP_SESSION_KEY, INITIAL_APP_SESSION_VALUE);
    return now < timeout && locationPath === url;
};
exports.isSessionValid = isSessionValid;
